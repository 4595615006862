import React from 'react'
import tw, { styled } from 'twin.macro'

import { MainLayout } from '@common/layouts'
import { SEO } from '@components'

const Content = styled.div`
  ${tw`prose prose mx-auto max-w-screen-xl text-justify leading-6 antialiased py-16 px-6 text-gray-800`}
`

const PrivacyPolicyPage = ({ siteUrl }) => {
  return (
    <MainLayout>
      <Content>
        <SEO title="Privacy Policy" />

        <h1>HelloMD Privacy Policy</h1>
        <div class="mb-4">Last modified: July 1, 2021</div>
        <p>
          HelloMD Corporation (dba "HelloMD") (<strong>"Company"</strong> or <strong
          >"We"</strong>) respects your privacy and is committed to protecting it
          through our compliance with this policy.
        </p>
        <p>
          This policy describes the types of information we may collect from you or
          that you may provide when you visit the website www.helloMD.com (our
          <strong>"Website"</strong>) and our practices for collecting, using,
          maintaining, protecting, and disclosing that information
          <i>
            in accordance with the Personal Information Protection and Electronic
            Documents Act (“PIPEDA”)</i>.
        </p>
        <p>This policy applies to information we collect:</p>
        <ul>
          <li>On this Website.</li>
          <li>
            In email, text, and other electronic messages between you and this
            Website.
          </li>
          <li>
            When you interact with our advertising and applications on third-party
            websites and services, if those applications or advertising include links
            to this policy.
          </li>
        </ul>
        <p>It does not apply to information collected by:</p>
        <ul>
          <li>
            us offline or through any other means, including on any other website
            operated by Company or any third party; or
          </li>
          <li>
            any third party, including through any application or content (including
            advertising) that may link to or be accessible from or on the Website
          </li>
        </ul>
        <p>
          Please read this policy carefully to understand our policies and practices
          regarding your information and how we will treat it. If you do not agree
          with our policies and practices, your choice is not to use our Website. By
          accessing or using this Website, you agree to this privacy policy. This
          policy may change from time to time (see Changes to Our Privacy Policy).
          Your continued use of this Website after we make changes is deemed to be
          acceptance of those changes, so please check the policy periodically for
          updates.
        </p>
        <p>Users Under the Age of 18</p>
        <p>
          Our Website is not intended for minors. No minor may provide any information
          to or on the Website. We do not knowingly collect personal information from
          minors. If you are a minor, do not use or provide any information on this
          Website or on or through any of its features, make any purchases through the
          Website, use any of the interactive or public comment features of this
          Website or provide any information about yourself to us, including your
          name, address, telephone number, email address, or any screen name or user
          name you may use. If we learn we have collected or received personal
          information from a minor without verification of parental consent, we will
          delete that information. If you believe we might have any information from
          or about a minor, please contact us at <a
            href="mailto:support@hellomd.com"
          >support@hellomd.com</a>.
        </p>
        <h2>Information We Collect About You and How We Collect It</h2>
        <p>
          We collect several types of information from and about users of our Website,
          including information:
        </p>
        <ul>
          <li>
            by which you may be personally identified, such as name, postal address,
            e-mail address, telephone number, social security number or ANY OTHER
            INFORMATION THE WEBSITE COLLECTS THAT IS DEFINED AS PERSONAL OR PERSONALLY
            IDENTIFIABLE INFORMATION UNDER AN APPLICABLE LAW ("<strong>personal
              information</strong>"); and/or
          </li>
          <li>
            about your internet connection, the equipment you use to access our
            Website and usage details.
          </li>
        </ul>
        <p>We collect this information:</p>
        <ul>
          <li>Directly from you when you provide it to us.</li>
          <li>
            Automatically as you navigate through the Website. Information collected
            automatically may include usage details, IP addresses, and information
            collected through cookies, web beacons, and other tracking technologies.
          </li>
          <li>From third parties, for example, our business partners.</li>
        </ul>
        <h2>Information You Provide to Us</h2>
        <p>The information we collect on or through our Website may include:</p>
        <ul>
          <li>
            Information that you provide by filling in forms on our Website. This
            includes information provided at the time of registering to use our
            Website, subscribing to our service, posting material, or requesting
            further services. We may also ask you for information when you report a
            problem with our Website.
          </li>
          <li>
            Records and copies of your correspondence (including email addresses), if
            you contact us.
          </li>
          <li>
            Your responses to surveys that we might ask you to complete for research
            purposes.
          </li>
          <li>Your search queries on the Website.</li>
          <li>
            Specific health information which we collect for presentation to health
            care practitioners that you may choose to engage with though our Website.
          </li>
        </ul>
        <p>
          You also may provide information to be published or displayed (hereinafter,
          <strong>"posted"</strong>) on public areas of the Website, or transmitted to
          other users of the Website or third parties (collectively,
          <strong>"User Contributions"</strong>). Your User Contributions are posted
          on and transmitted to others at your own risk. Although, please be aware
          that no security measures are perfect or impenetrable. Additionally, we
          cannot control the actions of other users of the Website with whom you may
          choose to share your User Contributions. Therefore, we cannot and do not
          guarantee that your User Contributions will not be viewed by unauthorized
          persons.
        </p>
        <p>
          <strong>Information We Collect Through Automatic Data Collection
            Technologies</strong>. As you navigate through and interact with our
          Website, we may use automatic data collection technologies to collect
          certain information about your equipment, browsing actions, and patterns,
          including:
        </p>
        <ul>
          <li>
            Details of your visits to our Website, including traffic data, location
            data, logs, and other communication data and the resources that you access
            and use on the Website.
          </li>
          <li>
            Information about your computer and internet connection, including your IP
            address, operating system, and browser type.
          </li>
        </ul>
        <p>
          The information we collect automatically may include personal information or
          we may maintain it or associate it with personal information we collect in
          other ways or receive from third parties. It helps us to improve our Website
          and to deliver a better and more personalized service, including by enabling
          us to:
        </p>
        <ul>
          <li>Estimate our audience size and usage patterns.</li>
          <li>
            Store information about your preferences, allowing us to customize our
            Website according to your individual interests.
          </li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you return to our Website.</li>
        </ul>
        <p>The technologies we use for this automatic data collection may include:</p>
        <ul>
          <li>
            <strong>Cookies (or browser cookies).</strong> A cookie is a small file
            placed on the hard drive of your computer. You may refuse to accept
            browser cookies by activating the appropriate setting on your browser.
            However, if you select this setting you may be unable to access certain
            parts of our Website. Unless you have adjusted your browser setting so
            that it will refuse cookies, our system will issue cookies when you direct
            your browser to our Website.
          </li>
          <li>
            <strong>Flash Cookies.</strong> Certain features of our Website may use
            local stored objects (or Flash cookies) to collect and store information
            about your preferences and navigation to, from, and on our Website. Flash
            cookies are not managed by the same browser settings as are used for
            browser cookies. For information about managing your privacy and security
            settings for Flash cookies, see Choices About How We Use and Disclose Your
            Information.
          </li>
          <li>
            <strong>Web Beacons.</strong> Pages of our the Website and our e-mails may
            contain small electronic files known as web beacons (also referred to as
            clear gifs, pixel tags, and single-pixel gifs) that permit the Company,
            for example, to count users who have visited those pages or opened an
            email and for other related website statistics (for example, recording the
            popularity of certain website content and verifying system and server
            integrity).
          </li>
        </ul>
        <h2>Third-Party Use of Cookies and Other Tracking Technologies.</h2>
        <p>
          Some content or applications, including advertisements, on the Website are
          served by third-parties, including advertisers, ad networks and servers,
          content providers, and application providers. These third parties may use
          cookies alone or in conjunction with web beacons or other tracking
          technologies to collect information about you when you use our Website. The
          information they collect may be associated with your personal information or
          they may collect information, including personal information, about your
          online activities over time and across different websites and other online
          services. They may use this information to provide you with interest-based
          (behavioral) advertising or other targeted content.
        </p>
        <p>
          We do not control these third parties' tracking technologies or how they may
          be used. If you have any questions about an advertisement or other targeted
          content, you should contact the responsible provider directly. For
          information about how you can opt out of receiving targeted advertising from
          many providers, see Choices About How We Use and Disclose Your Information.
        </p>
        <h2>How We Use Your Information</h2>
        <p>
          We use information that we collect about you or that you provide to us,
          including any personal information:
        </p>
        <ul>
          <li>To present our Website and its contents to you.</li>
          <li>
            To provide you with information, products, or services that you request
            from us. To fulfill any other purpose for which you provide it.
          </li>
          <li>
            To provide you with notices about your account, including expiration and
            renewal notices.
          </li>
          <li>
            To carry out our obligations and enforce our rights arising from any
            contracts entered into between you and us, including for billing and
            collection
          </li>
          <li>
            To notify you about changes to our Website or any products or services we
            offer or provide though it.
          </li>
          <li>To allow you to participate in interactive features on our Website.</li>
          <li>
            To present to health care practitioners that you may engage with though
            HelloMD.
          </li>
          <li>In any other way we may describe when you provide the information</li>
          <li>For any other purpose with your consent.</li>
        </ul>
        <p>
          We may also use your information to contact you about our own and
          third-parties' goods and services that may be of interest to you. If you do
          not want us to use your information in this way, please adjust your user
          preferences in your account profile. For more information, see Choices About
          How We Use and Disclose Your Information.
        </p>
        <p>
          We may use the information we have collected from you to enable us to
          display advertisements to our advertisers' target audiences. Even though we
          do not disclose your personal information for these purposes without your
          consent, if you click on or otherwise interact with an advertisement, the
          advertiser may assume that you meet its target criteria.
        </p>
        <h2>Disclosure of Your Information</h2>
        <p>
          We may disclose aggregated information about our users, and information that
          does not identify any individual, without restriction.
        </p>
        <p>
          We may disclose personal information that we collect or you provide as
          described in this privacy policy:
        </p>
        <ul>
          <li>To our subsidiaries and affiliates.</li>
          <li>
            To contractors, service providers, and other third parties we use to
            support our business and who are bound by contractual obligations to keep
            personal information confidential and use it only for the purposes for
            which we disclose it to them.
          </li>
          <li>
            To a buyer or other successor in the event of a merger, divestiture,
            restructuring, reorganization, dissolution, or other sale or transfer of
            some or all of HelloMD's assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which personal
            information held by HelloMD about our Website users is among the assets
            transferred.
          </li>
          <li>
            To third parties to market their products or services to you if you have
            [consented to/not opted out of] these disclosures. For more information,
            see Choices About How We Use and Disclose Your Information.
          </li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>
            For any other purpose disclosed by us when you provide the information.
          </li>
          <li>
            With your consent.
            <br />
            We may also disclose your personal information:
          </li>
          <li>
            To comply with any court order, law, or legal process, including to
            respond to any government or regulatory request.
          </li>
          <li>
            To enforce or apply our <a href="/terms-of-use">terms
              of use</a> and other agreements, including for billing and collection
            purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the
            rights, property, or safety of HelloMD, our customers, or others. This
            includes exchanging information with other companies and organizations for
            the purposes of fraud protection and credit risk reduction.
          </li>
        </ul>
        <h2>Choices About How We Use and Disclose Your Information</h2>
        <p>
          We strive to provide you with choices regarding the personal information you
          provide to us. We have created mechanisms to provide you with the following
          control over your information:
        </p>
        <ul>
          <li>
            <strong>Tracking Technologies and Advertising.</strong> You can set your
            browser to refuse all or some browser cookies, or to alert you when
            cookies are being sent. To learn how you can manage your Flash cookie
            settings, visit the Flash player settings page on Adobe's website. If you
            disable or refuse cookies, please note that some parts of this site may
            then be inaccessible or not function properly.
          </li>
          <li>
            <strong>Promotional Offers from the Company.</strong> If you do not wish
            to have your email address/contact information used by the Company to
            promote our own products or services, you can opt-out by logging into the
            Website and adjusting your user preferences in your account profile by
            checking or unchecking the relevant boxes or by sending us an email
            stating your request to <a
              href="mailto:support@hellomd.com"
            >support@hellomd.com</a>.
          </li>
          <li>
            We do not control third parties' collection or use of your information to
            serve interest-based advertising. However these third parties may provide
            you with ways to choose not to have your information collected or used in
            this way. You can opt out of receiving targeted ads from members of the
            Network Advertising Initiative ("<strong>NAI</strong>") on the NAI's
            website.
          </li>
        </ul>
        <h2>Accessing and Correcting Your Information</h2>
        <p>
          You can review and change your personal information by logging into the
          Website and visiting your account profile page.
        </p>
        <p>
          You may also send us an email at <a
            href="mailto:support@hellomd.com"
          >support@hellomd.com</a> to request access to, correct or delete any
          personal information that you have provided to us. We cannot delete your
          personal information except by also deleting your user account. We may not
          accommodate a request to change information if we believe the change would
          violate any law or legal requirement or cause the information to be
          incorrect.
        </p>
        <h2>Data Security</h2>
        <p>
          We have implemented measures designed to secure your personal information
          from accidental loss and from unauthorized access, use, alteration, and
          disclosure. All information you provide to us is stored on our secure
          servers behind firewalls. Any payment transactions are encrypted.
        </p>
        <p>
          The safety and security of your information also depends on you. Where we
          have given you (or where you have chosen) a password for access to certain
          parts of our Website, you are responsible for keeping this password
          confidential. We ask you not to share your password with anyone. We urge you
          to be careful about giving out information in public areas of the Website
          like message boards. The information you share in public areas may be viewed
          by any user of the Website.
        </p>
        <p>
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal information
          transmitted to our Website. Any transmission of personal information is at
          your own risk. We are not responsible for circumvention of any privacy
          settings or security measures contained on the Website.
        </p>
        <h2>Changes to Our Privacy Policy</h2>
        <p>
          It is our policy to post any changes we make to our privacy policy on this
          page. If we make material changes to how we treat our users' personal
          information, we will notify you by email to the primary email address
          specified in your account and/or through a notice on the Website home page.
          The date the privacy policy was last revised is identified at the top of the
          page. You are responsible for ensuring we have an up-to-date active and
          deliverable email address for you, and for periodically visiting our Website
          and this privacy policy to check for any changes.
        </p>
        <h2>Contact Information</h2>
        <p>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us at: <a
            href="mailto:support@hellomd.com"
          >support@hellomd.com</a>
        </p>
        <p>
          HelloMD’s Chief Privacy Officer manages and monitors this Privacy Policy and
          the security of personal information that is within the control of HelloMD.
          If you would like to access your information, if you have any questions or
          comments, if you find any errors in the personal information we have about
          you, or if you would like to make a complaint about our handling of your
          personal information, please contact our Chief Privacy Officer at
          support@hellomd.com
        </p>
      </Content>
    </MainLayout>
  )
}

export default PrivacyPolicyPage
